<template>
  <div>
    <v-container >
      <UnitCodeList/>
    </v-container>
  </div>
</template>

<script>
import UnitCodeList from '../../../components/Misc/UnitCode/UnitCodeList.vue';

export default {
  name: 'MiscUnitSchoolCode',
  components: {
    UnitCodeList,
  },
  data: () => ({
  }),
  created() {
  },
  computed: {
  },
  methods: {
  },
};

</script>
