<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="2">
        <v-select
          class="shared-source-fiscalYear"
          label="Fiscal Years"
          v-model="editedItem.fiscalYear"
          :items="fiscalYearRange"
        ></v-select>
      </v-col>
        <v-col cols="3">
          <v-text-field
            label="Unit Code"
            v-model="editedItem.unitCode"
          ></v-text-field>
        </v-col>
        <v-col col="3">
          <v-text-field
            label="Unit Code Number"
            v-model="editedItem.unitCodeNumber"
          ></v-text-field>
        </v-col>
        <v-col col="3">
          <v-text-field
            label="Description"
            v-model="editedItem.description"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import {
  getFYList,
} from '../../../util/shared/tmc-global';

export default {
  name: 'unitCodeCriteria',
  components: {
  },
  props: {
    baseCrudKey: {
      type: String,
      default: 'unitCodeDialog',
    },
  },
  created() {
  },
  computed: {
    ...mapGetters('base/crud', [
      'item',
    ]),
    ...mapState({
      fiscalYearRange: (state) => {
        const registerdYears = state.misc.schoolUnit.regYears || {};
        return getFYList(registerdYears);
      },
    }),
    editedItem: {
      get() {
        return this.item(this.baseCrudKey);
      },
      set(value) {
        this.setItem([this.baseCrudKey, value]);
      },
    },
  },
  methods: {
    ...mapMutations('base/crud', [
      'setItem',
    ]),
  },
};
</script>
