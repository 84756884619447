<template>
  <div>
    <CRUDCriteria
      :showReset="true"
      :showSearch="false"
    >
      <template v-slot:criteriaRows>
        <v-row>
          <v-col cols="2">
              <v-select
                id="tmc-unit-code-criteria-fy"
                label="By FY"
                :items="fiscalYearRange"
                v-model.number="criteriaState.fiscalYear"
                @input="searchByCriteria"
              ></v-select>
            </v-col>
          <v-col cols="5">
            <v-text-field
              id="accountWildCard"
              v-model="criteriaState.wildcard"
              label="Wildcard search (unit code, unit code number or desc)"
              @input="wildcardSearchDelayed"
              autocomplete="off"
          ></v-text-field>
          </v-col>
        </v-row>
      </template>
    </CRUDCriteria>
  </div>
</template>
<script>
import {
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import CRUDCriteria from '../../common/base/crud/CRUDCriteria.vue';
import {
  getFYList,
} from '../../../util/shared/tmc-global';

export default {
  name: 'SchoolCriteria',
  components: {
    CRUDCriteria,
  },
  data: () => ({
    delayTimerId: undefined,
  }),
  props: {
    baseCrudKey: {
      type: String,
      default: 'unitCodeCriteria',
    },
  },
  created() {
  },
  computed: {
    ...mapGetters('base/crud', [
      'criteria',
    ]),
    ...mapGetters(['todaysDate']),
    ...mapState({
      fiscalYearRange: (state) => {
        const registerdYears = state.misc.schoolUnit.regYears || {};
        return getFYList(registerdYears);
      },
    }),
    criteriaState: {
      get() {
        return this.criteria(this.baseCrudKey);
      },
      set(value) {
        this.setCriteria([this.baseCrudKey, value]);
      },
    },
  },
  methods: {
    ...mapMutations('base/crud', [
      'setCriteria',
    ]),
    wildcardSearchDelayed() {
      // cancel pending call
      clearTimeout(this.delayTimerId);
      // delay new call
      this.delayTimerId = setTimeout(async () => {
        const criteriaArgs = { searchMode: 'wildcard' };
        await this.handleLoadCRUDList(criteriaArgs);
      }, 250);
    },
    handleLoadCRUDList(criteriaArgs = {}) {
      this.$emit('loadCRUDList', criteriaArgs);
    },
    searchByCriteria() {
      this.handleLoadCRUDList();
    },
  },
};
</script>
