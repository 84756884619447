<template>
  <div
    class="SchoolUnitList">
    <UnitCodeCriteria
      :baseCrudKey="baseCrudKey"
      @loadCRUDList="handleLoadCRUDList"
    />
    <CRUDList
      :canAdd="true"
      :canEdit="true"
      :canSave="true"
      :headers="headers"
      :showDate="true"
      :showTotal="true"
      :baseCrudKey="baseCrudKey"
      :createNewItem="newItemDialog"
      :defaultSortBy="sortBy"
      toolbarTitle="School/Unit Code"
      @loadCRUDList="handleLoadCRUDList"
      @upsertItem="upsrtSchool"
      >
      <template v-slot:[`editedItem`]="{}">
        <UnitCodeEditedItem :baseCrudKey="baseCrudKey"/>
      </template>
    </CRUDList>
  </div>
</template>

<script>
import {
  mapGetters,
  mapActions,
  mapState,
  mapMutations,
} from 'vuex';

import CRUDList from '../../common/base/crud/CRUDList.vue';
import UnitCodeCriteria from './UnitCodeCriteria.vue';
import UnitCodeEditedItem from './UnitCodeEditedItem.vue';
import {
  toFiscalYear,
} from '../../../util/shared/tmc-global';

const getHeaders = () => {
  const headers = [
    { text: 'Unit Code', value: 'unitCode' },
    { text: 'Unit Code Number', value: 'unitCodeNumber' },
    { text: 'Description', value: 'description' },
  ];
  return headers;
};

export default {
  data: () => ({
    baseCrudKey: 'miscUnitCode',
    headers: [],
    sortBy: [
      {
        column: 'unitCodeNumber',
        direction: 'asc',
      },
    ],
  }),
  created() {
    this.headers = getHeaders();
    this.initCriteria();
    this.loadFiscalRegYears();
    this.criteriaState.fiscalYear = toFiscalYear(new Date(this.todaysDate));
  },
  components: {
    CRUDList,
    UnitCodeCriteria,
    UnitCodeEditedItem,
  },
  computed: {
    ...mapGetters('base/crud', [
      'criteria',
      'index',
    ]),
    ...mapGetters(['todaysDate']),
    ...mapState({
      sourceType: (state) => {
        const sourceTypeVals = state.enums.cmnSourceTypeEnum || [];
        return [{ name: null, description: '' }, ...sourceTypeVals];
      },
    }),
    criteriaState: {
      get() {
        return this.criteria(this.baseCrudKey);
      },
      set(value) {
        this.setCriteria([this.baseCrudKey, value]);
      },
    },
  },
  methods: {
    ...mapActions('misc/schoolUnit', [
      'loadMiscUnitCode',
      'upsertMiscUnitCode',
      'loadFiscalRegYears',
    ]),
    ...mapMutations('base/crud', [
      'setCriteria',
    ]),
    handleLoadCRUDList(criteriaArgs = {}) {
      const { baseCrudKey } = this;
      const criteria = this.criteriaState;
      this.loadMiscUnitCode({
        criteria,
        baseCrudKey,
        criteriaArgs,
      });
    },
    isNewItem() {
      return this.index(this.baseCrudKey) === -1;
    },
    upsrtSchool(item) {
      const { baseCrudKey } = this;
      if (!this.isNewItem()) {
        if (!window.confirm('This update will reflect to all associated places, are you sure!')) {
          return false;
        }
      }
      this.upsertMiscUnitCode({ item, baseCrudKey });
      return true;
    },
    initCriteria() {
      // reset criteria while merge flag is true
      this.criteriaState = {};
    },
    newItemDialog() {
      let newItem = {};
      newItem = {
        fiscalYear: toFiscalYear(new Date(this.todaysDate)),
        unitCode: null,
        unitCodeNumber: null,
        description: null,
      };
      return newItem;
    },
  },
};
</script>
